import API from "..";

export default class Batch {

  constructor({
    id, 
    blendID, 
    blendRequest,
    blendFacilityName, 
    blendFacility,
    blendPriorityName,
    blendPriority, 
    batchComments,
    requestedCompletionDate,
    gpaqsNumber,
    actualAmount,
    unitOfMeasureActual,
    preparedByEmail,
    preparedDate,
    lastModifiedByEmail,
    lastModifiedDate,
    createdByEmail,
    createdDate,
    destination,
    requestedAmount,
    requiresBlindCode,
    shippingInfo,
    preparedSampleName,
    preparedSample,
    requestedAmountUoM,
    unitOfMeasureRequested,
    batchStatusName,
    batchStatus,
    batchSequenceNumber,
    batchPrecision,
    priorityReason,
    statusReason,
    batchRetainLocationName,
    batchRetainLocation,
    batchCertificationStatusName,
    batchCertificationStatus,
    lastCertifiedByEmail,
    lastCertifiedDate,
    shelfLifeStartDate,
    shelfLifeEndDate,
    waiverReason,
    retainReason,
    batchPurpose,
    batchComponents,
    shippingAddressID,
    batchAttachments,
    analyticalTesting,
    analyticalTestingOwnerEmail,
    batchProductGroupName,
    batchProductGroup,
    chargeCode,
    cmaOilCode,
    isISOBlend,
    isMetalsFree,
    proMISRCode,
    safetyInformation,
    reference,
    description
}) 
    
  {
    this.id = id;
    this.blendID = blendID;
    this.blendRequest = blendRequest;
    this.blendFacilityName = blendFacilityName; 
    this.blendFacility = blendFacility;
    this.blendPriorityName = blendPriorityName;
    this.blendPriority = blendPriority;
    this.batchComments = batchComments;
    this.requestedCompletionDate = requestedCompletionDate;
    this.gpaqsNumber = gpaqsNumber;
    this.actualAmount = actualAmount;
    this.unitOfMeasureActual = unitOfMeasureActual;
    this.preparedByEmail = preparedByEmail;
    this.preparedDate = preparedDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.destination = destination;
    this.requestedAmount = requestedAmount;
    this.requiresBlindCode = requiresBlindCode;
    this.shippingInfo = shippingInfo;
    this.preparedSampleName = preparedSampleName;
    this.preparedSample = preparedSample;
    this.requestedAmountUoM = requestedAmountUoM;
    this.unitOfMeasureRequested = unitOfMeasureRequested;
    this.batchStatusName = batchStatusName;
    this.batchStatus = batchStatus;
    this.batchSequenceNumber = batchSequenceNumber;
    this.batchPrecision = batchPrecision;
    this.priorityReason = priorityReason;
    this.statusReason = statusReason;
    this.batchRetainLocationName = batchRetainLocationName;
    this.batchRetainLocation = batchRetainLocation;
    this.batchCertificationStatusName = batchCertificationStatusName;
    this.batchCertificationStatus = batchCertificationStatus;
    this.lastCertifiedByEmail = lastCertifiedByEmail;
    this.lastCertifiedDate = lastCertifiedDate;
    this.shelfLifeStartDate = shelfLifeStartDate;
    this.shelfLifeEndDate = shelfLifeEndDate;
    this.waiverReason = waiverReason;
    this.retainReason = retainReason;
    this.batchPurpose = batchPurpose;
    this.batchComponents = batchComponents;
    this.shippingAddressID = shippingAddressID;
    this.batchAttchments = batchAttachments;
    this.analyticalTesting = analyticalTesting;
    this.analyticalTestingOwnerEmail = analyticalTestingOwnerEmail;
    this.batchProductGroupName = batchProductGroupName;
    this.batchProductGroup = batchProductGroup;
    this.chargeCode = chargeCode;
    this.cmaOilCode = cmaOilCode;
    this.isISOBlend = isISOBlend;
    this.isMetalsFree = isMetalsFree;
    this.proMISRCode = proMISRCode;
    this.safetyInformation = safetyInformation;
    this.reference = reference;
    this.description = description;
  }

  static async getBatchByID(batchID) {
    const api = await API();
    const { data } = await api.get(`/batch/${batchID}`);
    if (data.result) {
      return data;
    }
    else {
      return data;
    }
  }

  static async AuditBatch(midasNumber, studyBlend, studyBlendBatch) {
    const api = await API();
    const { data } = await api.get(`/batch/auditBatch?midasNumber=${midasNumber}&studyBlend=${studyBlend}&studyBlendBatch=${studyBlendBatch}`);
    return data;
  }

  static async UpdateBatchCertificationStatus(listBatches) {
    const api = await API();
    const { data } = await api.post(`/batch/updateBatchCertificationStatus`, listBatches);
    return data;
  }

  static async create(supplierlot) {
    const api = await API();
    const { data } = await api.post(`/supplierlot`, supplierlot);
    return data;
  }

  static async generateComponentAvailabilityReport(listBatchIDs, unitOfMeasureName) {
    const api = await API();
    const { data } = await api.post(`/batch/componentAvailabilityReport?unitOfMeasure=${unitOfMeasureName}`, listBatchIDs);
    return data;
  }
  static async SubmitPreparedBatch(batchSubmission) {
    const api = await API('multipart/form-data');
    const { data } = await api.post(`/batch/createPreparedBatch`, batchSubmission);
    return data;
  }

  static async updateAssignedBlender(batchID, userName) {
    const api = await API();
    const { data } = await api.put(`/batch/updateAssignedBlender?batchID=${batchID}&userName=${userName}`);
    return data;
  }

  static async updateBatchStatus(batchID, batchStatus, statusReason) {
    const api = await API();
    const { data } = await api.put(`/batch/updateStatus?batchID=${batchID}&batchStatus=${batchStatus}&statusReason=${statusReason}`);
    return data;
  }

  static async updateBatchActuals(batchID, actualAmount, actualAmountUoMName) {
    const api = await API();
    const { data } = await api.put(`/batch/updateActuals?batchID=${batchID}&actualAmount=${actualAmount}&actualUoM=${actualAmountUoMName}`);
    return data;
  }
}
