import React, { useState, useContext, useEffect } from "react";
import { Box} from "@mui/material";
import TableAllBatches from "./TableAllBatches";
import TableMyBatches from "./TableMyBatches";
import FilterMenu from "../../components/FilterMenu"
import UserContext from "../../context/UserContext";
import BatchQueue from "../../api/Formulations/BatchQueue";
import BlendPriority from "../../api/Formulations/BlendPriority";
import BatchStatus from "../../api/Formulations/BatchStatus";
import UnitOfMeasure from "../../api/Admin/UnitOfMeasure";
import { GlobalButton, GlobalSecondaryButton, GlobalTabCollection, StyledTab } from "../../pages/styles";
import BlendFacility from "../../api/Formulations/BlendFacility";

const PrepareBatch = ({ ...props }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([{name:null, displayName:null, operator:null, enumValues:[], value:''}])

  const [allBatches, setAllBatches] = useState([])
  const [isBatchesLoading, setBatchesLoading] = useState(true)
  const [myBatches, setMyBatches] = useState([])
  const [isMyBatchesLoading, setIsMyBatchesLoading] = useState(true)

  //this is used as a way to force an update of the batches should the assinged blender be updated in the table component
  const [refreshData, setRefreshData] = useState(false)

  const currentUser = useContext(UserContext) 
  let inputSearchCriteria = currentUser.username

  const [batchStatuses, setBatchStatuses] = useState([])
  const [unitOfMeasures, setUnitOfMeasures] = useState([])
  const [blendStudyPriorities, setBlendStudyPriorities] = useState([])
  const [blendFacilities, setBlendFacilities] = useState([])

  const filterOptions = [{name: "studyName", displayName: "Study Name", type: "string", enumValues:[]}, 
  {name: "blendName",displayName: "Blend Name", type: "string", enumValues:[]}, 
  {name: "batchSequenceNumber",displayName: "Batch #", type: "integer", enumValues:[]},
  {name: "blendPriorityName",displayName: "Priority", type: "enum", enumValues: blendStudyPriorities},
  {name: "requestedCompletionDate",displayName: "Required Date", type: "date", enumValues:[]},
  {name: "requestedAmount",displayName: "Requested Amount", type: "integer", enumValues:[]},
  {name: "requestedAmountUoM",displayName: "UoM", type: "enum", enumValues: unitOfMeasures},
  {name: "ownerEmail",displayName: "Study Owner", type: "string", enumValues:[]},
  {name: "preparedByEmail",displayName: "Assigned Blender", type: "string", enumValues:[]},
  {name: "batchStatusName",displayName: "Status", type: "enum", enumValues: batchStatuses},
  {name: "age",displayName: "Age", type: "integer", enumValues:[]},
  {name: "blendFacilityName", displayName: "Facility", type: "enum", enumValues: blendFacilities}
]

   //get all the API info
  useEffect(() => {
    let cancelPromise = false

    BlendFacility.getAllFacilities().then((res) => {
      if (cancelPromise) return
        setBlendFacilities(res.filter(result => result.isActive === true).map((item) => (item.blendFacilityName)).sort())
    })

    BatchStatus.getAllBatchStatuses().then((res) => {
        if (cancelPromise) return
        setBatchStatuses(res.filter(result => result.isActive === true && (result.batchStatusName === 'InProgress' || result.batchStatusName === 'Submitted' || result.batchStatusName === 'Assigned' || result.batchStatusName === 'OnHold'
        )).map((item) => (item.batchStatusName)).sort())
    });

    UnitOfMeasure.getAll().then((res) => {
        if (cancelPromise) return
        setUnitOfMeasures(res.filter(result => (result.type === 'weight' || result.type ==='volume') && result.metricStandardConversion !== null && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
    });

    BlendPriority.getAllBlendPriorities().then((res) => {
        if (cancelPromise) return
        setBlendStudyPriorities(res.filter(result => result.isActive === true).map((item) => (item.blendPriorityName)).sort())
    });

      return () => {
        cancelPromise = true
      }
  }, [])  

    //get all the studies for owner
  useEffect(() => {
    let cancelPromise = false

    BatchQueue.getBatchActiveAssignedQueueByUser(inputSearchCriteria).then((res) => {
        if (cancelPromise) return
        setMyBatches(res)
        setIsMyBatchesLoading(false)
    });

    BatchQueue.getBatchActiveQueue().then((res) => {
        if (cancelPromise) return
        setAllBatches(res)
        setBatchesLoading(false)
    });

    return () => {
      cancelPromise = true
    }
  }, [inputSearchCriteria, refreshData])

  const handleChange = (event, newValue) => {
    clearFiltersClick()
    setTabValue(newValue);
  };

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  }

  const clearFiltersClick = (event) => {
    setFilters([{name:null, displayName:null, operator:null, enumValues:[], value:''}])  
  }

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <TableMyBatches 
                  filters={filters} 
                  myBatches={myBatches} 
                  isDataLoading={isMyBatchesLoading}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}
                  availableUoMs={unitOfMeasures}/>
      }
      case 1: {
        return <TableAllBatches 
                  filters={filters} 
                  allBatches={allBatches} 
                  isDataLoading={isBatchesLoading}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}/>
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const renderFilterMenu = () => {
    switch (tabValue) {
      case 0: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      case 1: {
        return  <FilterMenu 
                  open={filteringOpen} 
                  setOpen={setFilteringOpen} 
                  applyBtnAction={applyFilters} 
                  cancelButtonAction={closeFiltering} 
                  filteringInfo={filterOptions} 
                  appliedFilters={filters} 
                  setAppliedFilters={setFilters}/>
      }
      default: {
        alert(tabValue);
      }
    }
  };
  return (
       <div>
         <Box sx={{ bgcolor: "#fff", pt:3, pb:1 }} display="flex">
          <GlobalTabCollection style={{marginRight:"1rem"}} scrollButtons="auto" variant="scrollable" value={tabValue} onChange={handleChange} aria-label='ant example'>
            <StyledTab label='My Assigned Batches'/>
            <StyledTab label='All Active Batches'/>
          </GlobalTabCollection>

              <Box display="flex" alignItems={"center"} marginLeft="auto" marginTop="-.8rem">
                  <GlobalButton disabled={tabValue === 3 || tabValue === 4} style={{marginRight:"1rem"}}variant="contained"
                    onClick={() => filterClick()}>Filters</GlobalButton>

                  {!(filters[0].name === null) && 
                  <GlobalSecondaryButton  variant="contained" 
                    onClick={() => clearFiltersClick()}>Clear Filters</GlobalSecondaryButton>}
              </Box>
              
          <Box sx={{ p: 1 }} />
         </Box>
         {renderFilterMenu()}
        {renderSearchTab()}


       </div>
  );
};

export default PrepareBatch;
